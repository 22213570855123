<template>
    <div class="cooperationcaseList">
        <img class="bg-img" v-if="bannerInfo" :src="bannerInfo[0]">
        <div class="chain-router">
			<router-link to="/">首页</router-link>
			<em>></em>
			<span class="active">合作案例</span>
		</div>
        <div class="content-cls">
            <div class="main-title">
                <div class="big-title">合作案例</div>
                <div class="small-title">—   CUSTOM-MADE WITHOUT borders, do global business   —</div>
            </div>
            <div class="case-list">
                <div class="case-item" v-for="item in caseList" :key="item.id">
                    <img :src="item.img_id">
                    <div class="case-det">
                        <div class="case-title">
                            <h3><span>{{item.title}}</span></h3>
                            <span>{{item.country}}</span>
                        </div>
                        <p v-html="item.introduction"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: '',
    data() { 
        return {
            caseList:[],
        }
    },
    computed: {
        ...mapState({
            bannerInfo: state => state.bannerInfo[9], // banner图
        })
    },
    components:{
    },
    created() {
        this.getCooperationcaseList()
    },
    mounted() {
        
    },
    methods:{
        getCooperationcaseList(){
            //获取合作案例
            this.$api.getCooperationcaseList({}).then(res => {
                if (res.code == '0000') {
                    this.caseList = res.data;
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .cooperationcaseList{
        .bg-img{
            margin-block: 20px;
        }
        .main-title{
            margin: 53px 0 75px 0;
        }
        .case-list{
            .case-item{
                padding: 80px 100px;
                box-shadow: 0px 0px 15px 0px rgba(13,4,8,0.1000);
                border-radius: 16px;
                margin-bottom: 60px;
                display: flex;
                align-items: center;
                >img{
                    width: 300px;
                    height: 300px;
                    margin-right: 50px;
                }
                .case-det{
                    flex: 1;
                    .case-title{
                        display: flex;
                        margin-bottom: 50px;
                        align-items: flex-end;
                        >h3{
                            position: relative;
                            margin-right: 20px;
                            font-size: 44px;
                            color: #22529A;
                            font-weight: bold;
                            >span{
                                position: relative;
                                z-index: 1;
                            }
                            &::before{
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 11px;
                                z-index: 0;
                                background: #E0EBFB;
                            }
                        }
                        >span{
                            font-size: 20px;
                            color: #333333;
                        }
                    }
                    >p{
                        text-align: left;
                        font-size: 18px;
                        color: #333333;
                        line-height: 36px;
                    }
                }
            }
        }
    }
</style>